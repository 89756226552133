
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$langka-amulet-webapp-primary: mat.define-palette(mat.$indigo-palette);
$langka-amulet-webapp-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$langka-amulet-webapp-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$langka-amulet-webapp-theme: mat.define-light-theme((
  color: (
    primary: $langka-amulet-webapp-primary,
    accent: $langka-amulet-webapp-accent,
    warn: $langka-amulet-webapp-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($langka-amulet-webapp-theme);

/* You can add global styles to this file, and also import other style files */
/* ... styles ... */

html,
body {
  position: relative;
  margin: 0;
  height: 100%;
  box-sizing: border-box;
  background: rgba(251, 251, 251, 1) !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 !important;
  color: rgb(0, 0, 0)  ;
}

a {
  text-decoration: none !important;
  color: rgb(0, 0, 0) ;
}

@import './app/@core/scss/core.scss';

@import 'swiper/css';
@import 'swiper/css/free-mode';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';
@import 'swiper/css/thumbs';

@import "flag-icons/css/flag-icons.min.css";
@import "bootstrap/dist/css/bootstrap.css";
@import "@ng-select/ng-select/themes/default.theme.css";

@import "assets/scss/toastr.scss";

@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'bs-stepper/dist/css/bs-stepper.min.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
