@font-face {
  font-family: 'Noto Sans Thai';
  src: url('../fonts/NotoSansThai-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans Thai';
  src: url('../fonts/NotoSansThai-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans Thai';
  src: url('../fonts/NotoSansThai-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans Thai';
  src: url('../fonts/NotoSansThai-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans Thai';
  src: url('../fonts/NotoSansThai-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans Thai';
  src: url('../fonts/NotoSansThai-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans Thai';
  src: url('../fonts/NotoSansThai-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans Thai';
  src: url('../fonts/NotoSansThai-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans Thai';
  src: url('../fonts/NotoSansThai-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

html * {
  font-family: 'Noto Sans Thai', sans-serif !important;
}

.debug {
  border: red 1px solid !important;
}

a {
  text-decoration: none;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
