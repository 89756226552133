.show-toast {
  .inline {
    position: unset;
    z-index: unset;
    margin: 0px -24px;
    width: auto;
    max-width: revert;

    .ngx-toastr {
      width: unset;
      color: #58A329;
      box-shadow: none;

      .toast-title {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .toast-success {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzE5NTBfMTc2Njg3KSI+CjxwYXRoIGQ9Ik04IDBDNi40MTc3NSAwIDQuODcxMDMgMC40NjkxOTIgMy41NTU0NCAxLjM0ODI0QzIuMjM5ODUgMi4yMjcyOSAxLjIxNDQ3IDMuNDc2NzIgMC42MDg5NjcgNC45Mzg1M0MwLjAwMzQ2NjI3IDYuNDAwMzQgLTAuMTU0OTYgOC4wMDg4NyAwLjE1MzcyMSA5LjU2MDcyQzAuNDYyNDAzIDExLjExMjYgMS4yMjQzMyAxMi41MzggMi4zNDMxNSAxMy42NTY5QzMuNDYxOTcgMTQuNzc1NyA0Ljg4NzQzIDE1LjUzNzYgNi40MzkyOCAxNS44NDYzQzcuOTkxMTMgMTYuMTU1IDkuNTk5NjYgMTUuOTk2NSAxMS4wNjE1IDE1LjM5MUMxMi41MjMzIDE0Ljc4NTUgMTMuNzcyNyAxMy43NjAyIDE0LjY1MTggMTIuNDQ0NkMxNS41MzA4IDExLjEyOSAxNiA5LjU4MjI1IDE2IDhDMTUuOTk3NyA1Ljg3ODk4IDE1LjE1NDEgMy44NDU1IDEzLjY1NDMgMi4zNDU3MkMxMi4xNTQ1IDAuODQ1OTMyIDEwLjEyMSAwLjAwMjMyOTI4IDggMFpNMTAuOTY1NiA2Ljk2NTZMNy43NjU2IDEwLjE2NTZDNy42MTU1OCAxMC4zMTU2IDcuNDEyMTMgMTAuMzk5OCA3LjIgMTAuMzk5OEM2Ljk4Nzg3IDEwLjM5OTggNi43ODQ0MiAxMC4zMTU2IDYuNjM0NCAxMC4xNjU2TDUuMDM0NCA4LjU2NTZDNC44ODg2OCA4LjQxNDcyIDQuODA4MDQgOC4yMTI2MyA0LjgwOTg2IDguMDAyODhDNC44MTE2OSA3Ljc5MzEyIDQuODk1ODIgNy41OTI0NyA1LjA0NDE1IDcuNDQ0MTRDNS4xOTI0NyA3LjI5NTgyIDUuMzkzMTIgNy4yMTE2OCA1LjYwMjg4IDcuMjA5ODZDNS44MTI2NCA3LjIwODA0IDYuMDE0NzIgNy4yODg2NyA2LjE2NTYgNy40MzQ0TDcuMiA4LjQ2ODhMOS44MzQ0IDUuODM0NEM5Ljk4NTI4IDUuNjg4NjcgMTAuMTg3NCA1LjYwODA0IDEwLjM5NzEgNS42MDk4NkMxMC42MDY5IDUuNjExNjggMTAuODA3NSA1LjY5NTgyIDEwLjk1NTkgNS44NDQxNEMxMS4xMDQyIDUuOTkyNDcgMTEuMTg4MyA2LjE5MzEyIDExLjE5MDEgNi40MDI4OEMxMS4xOTIgNi42MTI2MyAxMS4xMTEzIDYuODE0NzIgMTAuOTY1NiA2Ljk2NTZaIiBmaWxsPSIjNThBMzI5Ii8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMTk1MF8xNzY2ODciPgo8cmVjdCB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==);
      background-color:#F0FAEB;
    }
  }
}
