// Pagination

// default look for page item, first and last item

.page-item {
  border-radius: 5rem;
  padding: 0 3px;

  &:not(.prev-item),
  &:not(.next-item),
  &:not(.first),
  &:not(.last),
  &:not(.active) {
    .page-link {
      line-height: 1.3;
    }
  }

  .page-link {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #9C9896;
    background-color: unset;
    font-weight: 700;
  }

  &.previous,
  &.prev,
  &.next,
  &.first,
  &.last {
    .page-link {
      width: auto;
    }
  }

  &.prev-item,
  &.next-item {
    .page-link {
      transition: all 0.2s ease-out;
    }
  }

  // prev and next item separated styles
  &.prev-item {
    margin-right: 0.3571rem;

    .page-link {
      border-radius: 50%;

      &:before {
        //background-image: url(str-replace(str-replace($chevron-left, 'currentColor', $pagination-color), '#', '%23'));
        background-repeat: no-repeat;
        background-position: center;
        background-size: 14px;
        color: #6e6b7b;
        width: 8px;
        height: 18px;
        content: '';
      }

      &:hover {
        background: #f3f2f7;
        color: #121FCF;
      }

      &:active,
      &:hover {
        background-color: #121FCF !important;

        &:before {
          //background-image: url(str-replace(str-replace($chevron-left, 'currentColor', $white), '#', '%23')) !important;
        }
      }
    }

    &.disabled {
      .page-link {
        &:before {
          //background-image: url(str-replace(
          //    str-replace($chevron-left, 'currentColor', $pagination-disabled-color),
          //    '#',
          //    '%23'
          //));
        }
      }
    }

    & ~ .page-item:nth-child(2) {
      border-top-left-radius: 5rem;
      border-bottom-left-radius: 5rem;

      .page-link {
        border-top-left-radius: 5rem !important;
        border-bottom-left-radius: 5rem !important;
      }
    }

    & ~ .page-item:nth-last-child(2) {
      border-top-right-radius: 5rem;
      border-bottom-right-radius: 5rem;

      .page-link {
        border-top-right-radius: 5rem !important;
        border-bottom-right-radius: 5rem !important;
      }
    }
  }

  &.next-item {
    margin-left: 0.3571rem;

    .page-link {
      border-radius: 50%;

      &:after {
        //background-image: url(str-replace(str-replace($chevron-right, 'currentColor', $pagination-color), '#', '%23'));
        background-repeat: no-repeat;
        background-position: center;
        background-size: 14px;
        width: 8px;
        height: 18px;
        content: '';
      }

      &:hover {
        background: #f3f2f7;
        color: #121FCF;
      }

      &:active,
      &:hover {
        background-color: #121FCF !important;

        &:after {
          //background-image: url(str-replace(
          //    str-replace($chevron-right, 'currentColor', $white),
          //    '#',
          //    '%23'
          //)) !important;
        }
      }
    }

    &.disabled {
      .page-link {
        &:after {
          //background-image: url(str-replace(
          //    str-replace($chevron-right, 'currentColor', $pagination-disabled-color),
          //    '#',
          //    '%23'
          //));
        }
      }
    }
  }

  // prev and next style
  &.prev,
  &.previous {
    .page-link {
      &:before {
        //background-image: url(str-replace(str-replace($chevron-left, 'currentColor', $pagination-color), '#', '%23'));
        background-repeat: no-repeat;
        background-position: center;
        background-size: 14px;
        color: #6e6b7b;
        width: 12px;
        height: 18px;
        content: '';
      }

      // &:active,
      &:hover {
        &:before {
          //background-image: url(str-replace(
          //    str-replace($chevron-left, 'currentColor', $pagination-active-color),
          //    '#',
          //    '%23'
          //)) !important;
        }
      }
    }

    &.disabled {
      .page-link {
        &:before {
          //background-image: url(str-replace(
          //    str-replace($chevron-left, 'currentColor', $pagination-disabled-color),
          //    '#',
          //    '%23'
          //));
        }
      }
    }
  }

  &.next {
    .page-link {
      &:after {
        //background-image: url(str-replace(str-replace($chevron-right, 'currentColor', $pagination-color), '#', '%23'));
        background-repeat: no-repeat;
        background-position: center;
        background-size: 14px;
        color: #6e6b7b;
        width: 12px;
        height: 18px;
        content: '';
      }

      // &:active,
      &:hover {
        &:after {
          //background-image: url(str-replace(
          //    str-replace($chevron-right, 'currentColor', $primary),
          //    '#',
          //    '%23'
          //)) !important;
        }
      }
    }

    &.disabled {
      .page-link {
        &:after {
          //background-image: url(str-replace(
          //    str-replace($chevron-right, 'currentColor', #b9b9c3),
          //    '#',
          //    '%23'
          //));
        }
      }
    }
  }

  &.disabled {
    .page-link {
      color: #b9b9c3;
      background: none;
    }
  }

  // page link customization

  .page-link {
    border: none;
    margin: 0;
    font-size: 1rem;
    min-width: 2.286rem;

    &:focus {
      box-shadow: none;
      color: #9C9896;
      background-color: unset;
    }

    &:hover {
      color: #9C9896;
      background: #ECF0F8;
      border-radius: 8px;
    }
  }

  // for active page item
  &.active {
    border-radius: 0;

    .page-link {
      z-index: 3;
      border-radius: 8px;
      background: linear-gradient(180deg, #0A2D8E 0%, #0E3190 6.81%, #3E62AC 100%);
      color: #fff !important;
      font-weight: 600;
    }
  }

  // if you don't want separated look

  &:first-child:not(.prev-item) {
    &.active {
      //border-top-left-radius: 1.428rem;
      //border-bottom-left-radius: 1.428rem;
      .page-link {
        border-radius: 5rem;
      }
    }

    .page-link {
      //border-top-left-radius: 1.428rem;
      //border-bottom-left-radius: 1.428rem;
    }
  }

  &:last-child:not(.next-item) {
    &.active {
      //border-top-right-radius: 1.428rem;
      //border-bottom-right-radius: 1.428rem;
      .page-link {
        border-radius: 5rem;
      }
    }

    .page-link {
      //border-top-right-radius: 1.428rem;
      //border-bottom-right-radius: 1.428rem;
    }
  }
}

// For Pagination Font sizes
.pagination {
  // For Pagination lg
  &.pagination-lg {
    .page-item {
      .page-link {
        font-size: 1.14rem;
        min-width: 2.6rem;
      }
    }
  }

  // For Pagination sm
  &.pagination-sm {
    .page-item {
      .page-link {
        font-size: 0.857rem;
        min-width: 2rem;
      }
    }
  }
}
